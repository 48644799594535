@import '../partials';

.layout-sidebar {

  .page-content {
    @include content-contaner;
    padding-top: rem(40);

    @include bp(md){
      padding-top: rem(90);
    }

    &__boundary {
      display: flex;
      flex-flow: column-reverse;
      flex-wrap: wrap;

      @include bp(md){
        flex-flow: row-reverse;
        flex-wrap: nowrap;
      }
    }
  }

  .flexible-content {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 rem(20);
  }
}